import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import marked from "marked"
import React, { useEffect } from "react"
import Moment from "react-moment"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import arrowDown from "../../images/down-arrow.png"
import person from "../../images/person-holder.jpg"

const slugify = require("slugify")
const remark = require("remark")
const externalLinks = require("remark-external-links")
const html = require("remark-html")

const PostContent = props => {
  const post = props.post
  const category = post.categories.map((cat, i) => cat.title).join(", ")
  const [mkd, setMkd] = React.useState()

  useEffect(() => {
    remark()
      .use(externalLinks, { rel: ["noopener noreferrer"] })
      .use(html)
      .process(post.body, function(err, file) {
        if (err) throw err
        setMkd(file)
        // console.log("TT", String(file))
      })
  }, [post.body])

  return (
    <>
      <div className="separator my-5 py-5">
        <span className="read-more-text">
          Read Next Post <img src={arrowDown} alt="Down arrow" />
        </span>
      </div>
      <div className="row justify-content-left">
        <div className="col-lg-9 pr-md-0">
          <div className="blog-content-wrapper">
            <div className="blog-single-content">
              <div className="title-post-meta">
                <Link to={`/category/${slugify(category.toLowerCase())}/`}>
                  {category}
                </Link>
                <GatsbyImage
                  image={
                    post.mainImage ? post.mainImage.asset.gatsbyImageData : ""
                  }
                  className="img-fluid"
                  layout="constrained"
                  style={{
                    height: "48px",
                    width: "48px",
                    visibility: "hidden",
                  }}
                />
              </div>

              <h1 className="font-weight-bold">{post.title}</h1>

              <div className="d-flex align-items-center title-post-meta">
                <div className="entry-author-image d-inline mr-2">
                  <GatsbyImage
                    image={
                      post.author.image
                        ? post.author.image.asset.gatsbyImageData
                        : person
                    }
                    className="img-fluid "
                    layout="constrained"
                    alt={post.author.name}
                    style={{ height: "48px", width: "48px" }}
                  />
                </div>
                <div>
                  <span>by</span>&nbsp;
                  <Link
                    to={`/author/${slugify(post.author.name.toLowerCase())}/`}
                    title={`Posts by ${post.author.name}`}
                    rel="author"
                    data-wpel-link="internal"
                  >
                    {post.author.name}
                  </Link>
                  <span> on </span>
                  <Moment add={{ days: 1, hours: 9 }} format="MMMM DD, YYYY">
                    {post.publishedAt !== null
                      ? post.publishedAt
                      : post._createdAt}
                  </Moment>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  {" "}
                  <ul className="list-unstyled d-flex">
                    <li className="mb-2 mr-2">
                      <FacebookShareButton
                        url={`https://quandarycg.com/${post.slug.current}`}
                      >
                        <FacebookIcon size={40} borderRadius={5} />
                      </FacebookShareButton>
                    </li>
                    <li className="mb-2 mx-2">
                      <TwitterShareButton
                        url={`https://quandarycg.com/${post.slug.current}`}
                      >
                        <TwitterIcon size={40} borderRadius={5} />
                      </TwitterShareButton>
                    </li>
                    <li className="mb-2 mx-2">
                      <LinkedinShareButton
                        url={`https://quandarycg.com/${post.slug.current}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinIcon size={40} borderRadius={5} />
                      </LinkedinShareButton>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="py-5 banner_img">
                <GatsbyImage
                  image={
                    post.mainImage ? post.mainImage.asset.gatsbyImageData : ""
                  }
                  // imgClassName="img-fluid"
                  className="img-fluid w-100"
                  alt="banner"
                  layout="constrained"
                  style={{
                    width: "885px",
                    height: "590px",
                  }}
                />
              </div>
              {(() => {
                if (post.custom_template === true) {
                  return (
                    <div
                      className="body"
                      dangerouslySetInnerHTML={{
                        __html: marked(post.body),
                      }}
                    />
                  )
                } else {
                  return (
                    <div
                      className="body"
                      dangerouslySetInnerHTML={{
                        __html: marked(String(mkd)),
                      }}
                    />
                  )
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostContent
